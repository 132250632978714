import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Chpt5SubDetail } from '../../../chapterSub/Chpt5Sub/Chpt5SubDetail'
import Footer from '../../../Footer/Footer'
import Nav from '../../../Nav/Nav'
import Newsletter from '../../../newsletter/Newsletter'
import TopBar from '../../../topbar/TopBar'
import "../../chapter1/GlobalVillage/GlobalVillage.css"

const Language = () => {
  return (
        <div>
            <TopBar/>
            <Nav/>
        <div className="container chapterOneMain">
            <div className="row chapterOneMain py-3">
                <div className="col-lg-9">
                <div className="booktop">
            <h1>পঞ্চম অধ্যায়</h1>
            <h3>প্রোগ্রামিং ভাষা</h3>
            <h3>প্রোগ্রামিং ভাষা (Programming Language)</h3>
        </div>

        <h4 className='mt-4'><b>প্রোগ্রামিং ভাষা (Programming Language) : </b></h4>
            <p>0 এবং 1 ব্যবহার করে লেখা সকল নির্দেশ মেনে কম্পিউটার কাজ করে। এই মেশিন ভাষা কম্পিটারের জন্য সহজে বোধগম্য হলেও আমাদের জন্য তা বেশ জটিল। তাই সময়ের সাথে সাথে প্রোগ্রামিংকে সহজ ও কার্যকরী করতে তৈরী করা হয়েছে নতুন নতুন প্রোগ্রামিং ভাষা। </p>
            <p>কম্পিউটারকে কোনো নির্দেশ বা instruction দেওয়ার জন্য কিছু নিয়ম-কানুন এবং চিহ্নের সাহায্য নিতে হয়। মূলত এই নিয়ম-কানুন এবং চিহ্নের সমন্বয়ে তৈরি করা হয় এক একটি প্রোগ্রামিং ভাষা বা Programming Language.</p>
        
        <h4><b>ভাষার প্রকারভেদ : </b></h4>
        <p>নিম্নস্তরের ভাষাঃ সাধারণত ১ম ও ২য় প্রজন্মের ভাষা নিম্নস্তর ভাষার অন্তর্গত। </p>
        <h4 className='mt-4'><b>১ম প্রজন্মের ভাষা (মেশিন ভাষা) : </b></h4>
                <p>সুতরাং কম্পিউটার ব্যবহার করে আমরা কোনো কাজ করাতে চাইলে তাকে সবকিছু 0 ও 1 ব্যবহার করে বলতে হবে বা নির্দেশ(instruction) দিতে হবে। মেশিন ভাষায় লেখা প্রোগ্রামকে object program-ও বলা হয়।</p>
                <p>কম্পিউটার শুধুমাত্র মেশিন ভাষাই বুঝতে পারে, তাই অন্য ভাষায় লেখা প্রোগ্রামকে কম্পিউটারে run করার জন্য উপোযুক্ত অনুবাদক ব্যবহার করে তা মেশিন ভাষার প্রোগ্রামে রুপান্তর করা হয়।</p>
                <h4><b>মেশিন ভাষার সুবিধা : </b></h4>
                <ul>
                    <li>খুব অল্প মেমোরি ব্যবহার করে মেশিন ভাষায় লেখা প্রোগ্রাম নির্বাহ বা run করা যায়।</li>
                    <li>মেশিন ভাষা অন্যান্য ভাষা হতে দ্রুত কাজ করে।</li>
                    <li>সরাসরি মেমোরি এড্রেস এর সাথে সংযোগ তৈরি করা সম্ভব।</li>
                    <li>কোড অনুবাদ করার জন্য কোন অনুবাদকের প্রয়োজন হয় না।</li>
                </ul>
                <h4><b>মেশিন ভাষার অসুবিধা : </b></h4>
                <ul>
                    <li>সমস্ত অপারেশন কোড মনে রাখতে হবে।</li>
                    <li>সব মেমরি ঠিকানা মনে রাখতে হবে।</li>
                    <li>মেশিন ভাষায় লেখা কোনো প্রোগ্রামে সংশোধন করা বা ত্রুটি খুঁজে বের করা কঠিন।</li>
                    <li>এক কম্পিউটারে লেখা প্রোগ্রাম অন্য কম্পিউটারে কাজ করে ।</li>
                </ul>
                <h4 className='mt-4'><b>২য় প্রজন্মের ভাষা ( অ্যাসেম্বলি ভাষা :) </b></h4>
                <p>মেশিন ভাষার অনেক অসুবিধা দূর করার জন্য অ্যাসেম্বলি ভাষা তৈরি করা হয়েছিল। এটি আরেকটি নিম্ন-স্তরের কিন্তু অত্যন্ত গুরুত্বপূর্ণ ভাষা যেখানে অপারেশন কোডগুলো 0 এবং 1 এর পরিবর্তে নেমোনিক কোড আকারে লেখা হয়।</p>
                <p>এই নেমোনিক কোডগুলো সর্বাধিক পাঁচ-অক্ষরের সংমিশ্রণে তৈরি হতে পারে, যেমন যোগের জন্য ADD, বিয়োগের জন্য SUB, এছারাও MUL, DIV, START, LABEL ইত্যাদি। এই বৈশিষ্ট্যের কারণে, অ্যাসেম্বলি ভাষা ‘সিম্বলিক প্রোগ্রামিং ভাষা’ নামেও পরিচিত।</p>
                <h4><b>অ্যাসেম্বলি ভাষার সুবিধা : </b></h4>
                <ul>
                    <li>মেশিন ভাষার তুলনায় অ্যাসেম্বলি ভাষা বোঝা এবং ব্যবহার করা সহজ।</li>
                    <li>ত্রুটি সনাক্ত করা এবং সংশোধন করা সহজ।</li>
                </ul>
                <h4><b>অ্যাসেম্বলি ভাষার অসুবিধা : </b></h4>
                <ul>
                    <li>মেশিন ভাষার মতো, এটিও মেশিন নির্ভর।</li>
                    <li>যেহেতু এটি মেশিন নির্ভর, তাই প্রোগ্রামারকেও হার্ডওয়্যার বুঝতে হবে।</li>
                    <li>এতে একটি প্রোগ্রাম লিখা মেশিন ভাষার তুলোনায় সহজ হলেও সময়সাপেক্ষ ও কষ্টকর।</li>
                </ul>
                <h4><b>মধ্যম স্তরের ভাষা : </b></h4>
                <p>মেশিন ভাষা এবং উচ্চস্তরের ভাষার মধ্যে সম্পর্ক স্থাপন করা মধ্যম স্তরের ভাষার কাজ। এই ভাষা ব্যবহার করে সিস্টেম প্রোগ্রামিং(অপারেটিং সিস্টেম) পাশাপাশি এপ্লিকেশন প্রোগ্রামিং করা যায়। C প্রোগ্রামিং ভাষা হচ্ছে মধ্যম স্তরের ভাষা। </p>
                <h4><b>৩য় প্রজন্মের ভাষা (উচ্চস্তরের ভাষা) : </b></h4>
                <p>মানুষ যেন সহজে তাদের নিজস্ব ভাষার মত করেই কম্পিটারের জন্য প্রোগ্রাম লিখতে পারে সেই উদ্দেশ্যে তৃতীয় প্রজন্ম তথা উচ্চ-স্তরের ভাষা আবিস্কার করা হয়। উচ্চ-স্তরের কম্পিউটার ভাষাগুলি ইংরেজি ভাষার কাছাকাছি গঠন ব্যবহার করে থাকে। C, C++,JAVA, PYTHON,RUBY  ইত্যাদি তৃতীয় প্রজন্মের ভাষার উদাহরণ।</p>

                <h4><b>মেশিন ভাষার সুবিধা : </b></h4>
                <ul>
                    <li>খুব অল্প মেমোরি ব্যবহার করে মেশিন ভাষায় লেখা প্রোগ্রাম নির্বাহ বা run করা যায়।</li>
                    <li>মেশিন ভাষা অন্যান্য ভাষা হতে দ্রুত কাজ করে।</li>
                    <li>সরাসরি মেমোরি এড্রেস এর সাথে সংযোগ তৈরি করা সম্ভব।</li>
                    <li>কোড অনুবাদ করার জন্য কোন অনুবাদকের প্রয়োজন হয় না।</li>
                </ul>
                <h4><b>উচ্চস্তরের ভাষার সুবিধা : </b></h4>
                <ul>
                    <li>উচ্চ-স্তরের ভাষা ব্যবহারকারী-বান্ধব।</li>
                    <li>এগুলি ইংরেজির মতো এবং ইংরেজি শব্দভাণ্ডার এবং সুপরিচিত প্রতীক ব্যবহার করে।</li>
                    <li>এই ভাষা শিখতে সহজ</li>
                    <li>এই ভাষা ‘মেশিন’-ভিত্তিক না হয়ে সমস্যা-ভিত্তিক।</li>
                    <li>একটি উচ্চ-স্তরের ভাষায় লিখিত একটি প্রোগ্রাম যে কোনো কম্পিউটারে চালানো যেতে পারে।</li>
                </ul>
                <h4><b>উচ্চস্তরের ভাষার অসুবিধা : </b></h4>
                <ul>
                    <li>কম্পিউটার সরাসরি বুঝতে পারেনা বিধায়,  অনুবাদকের সাহায্যে মেশিন ভাষায় অনুবাদ করতে হয়।</li>
                    <li> অনুবাদকের সাহায্যে উৎপন্ন অবজেক্ট কোড একটি সমতুল্য  অ্যাসেম্বলি ভাষা প্রোগ্রামের তুলনায় অদক্ষ হতে পারে।</li>
                    <li>প্রোগ্রাম run করতে বেশি সময় এবং মেমোরি প্রয়োজন হয়। </li>
                </ul>
                <h4><b>৪র্থ প্রজন্মের ভাষা(অতি-উচ্চস্তরের ভাষা) : </b></h4>
                    <p>কম্পিউটার প্রোগ্রামিং এর ভাষাকে মানুষের ভাষার আরও কাছাকাছি নিয়ে আসার চেস্টা থেকে পরবর্তীতে তৈরি হয় ৪র্থ প্রজন্মের ভাষা। ‘sql’ ৪র্থ প্রজন্মের ভাষার একটি উদাহরণ। কম্পিউটার ডাটাবেজ এ এর ব্যবহার করা হয়। </p>
                <h4><b>৫ম প্রজন্মের ভাষা(স্বাভাবিক বা ন্যাচারাল প্রোগ্রামিং ভাষা) : </b></h4>
                    <p>মানুষের স্বাভাবিক ভাষাকে কম্পিটারের নির্দেশ হিসেবে ব্যবহার করার চেষ্টা চলছে। এই প্রজন্মের ভাষায়  মানুষের মৌখিক নির্দেশকে মেশিন ভাষায় রূপান্তর করার জন্য কৃত্তিম বুদ্ধিমত্তা সম্পন্ন অনুবাদক ব্যবহার করা হয়। PROLOG, LISP, Mercury ইত্যাদি পঞ্চম প্রজন্মের ভাষার উদাহরণ।</p>
                </div>
                <div className="col-lg-3 ">
       
                <div className="chapterLeft">
                {
      Chpt5SubDetail.map((item,index)=>{
        return(
          <Link key={index} to={item.name} className="mb-4">
             <h4 className='chapterOne'>{item.title}</h4>
          </Link>
        )
      })
    }
                </div>
                </div>
        </div>
      </div>
      <Newsletter/>
      <Footer/>
        </div>
  )
}

export default Language