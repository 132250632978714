import Footer from "../../Footer/Footer"
import Nav from "../../Nav/Nav"
import Newsletter from "../../newsletter/Newsletter"
import TopBar from "../../topbar/TopBar"
import "./Terms.css"

const Terms = () => {
  return (
    <div>
        <TopBar/>
        <Nav/>
        <div className="footerItem">
        <div className="container ">
        <div className="faqTitle text-center mt-5 mb-4">
            <span>টার্মস এবং শর্তাবলী</span> 
            </div>
            <div className="row">
                <div className="cul-lg-12 termsAndCondition">
                    
                            <div className="footerSubItem">
                            <h4>ফাইল কপি ও পরিবর্তন নিষিদ্ধ :</h4>
                            <p>আপনি এই অ্যাপ্লিকেশন বা এর থেকে প্রাপ্ত কোনো তথ্য বা সফ্টওয়্যার অনুলিপি, পরিবর্তন, অনুবাদ, প্রদর্শন, বিতরণ, বা কোনোভাবে সম্পাদন করতে পারবেন না।</p>
                            </div>
                        
                        
                            <div className="footerSubItem">
                            <h4>বাণিজ্যিক ব্যবহার নিষিদ্ধ :</h4>
                            <p>আপনি এই অ্যাপ্লিকেশন, আমাদের ওয়েবসাইট, এবং আমাদের পণ্য ও পরিষেবাগুলিকে বাণিজ্যিক উদ্দেশ্যে ব্যবহার করতে পারবেন না। এর মধ্যে বিক্রি, বিজ্ঞাপন, বা প্রচারমূলক কার্যক্রমও অন্তর্ভুক্ত।</p>
                            </div>
                        
                        <div className="footerSubItem">
                            <h4>অনুমোদনহীন অ্যাক্সেস :</h4>
                            <p>সোশ্যাল মিডিয়া, ব্লগ, সংবাদপত্র বা কোনো মিডিয়া ব্যবহার করে আমাদের কোম্পানি বা এর পরিষেবাগুলির বিরুদ্ধে অপমানজনক বা হয়রানিমূলক তথ্য ছড়ানো সম্পূর্ণ নিষিদ্ধ।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>সাইবার বুলিং ও অপমানজনক কার্যকলাপ নিষিদ্ধ :</h4>
                            <p>সাইবার বুলিং, সোশ্যাল মিডিয়া মেম তৈরি করা, বা আমাদের ক্লাস ভিডিও ক্লিপ অংশ, স্ক্রিন শট, বা ওয়েবসাইটের অন্য কোনো সংস্থান ব্যবহার করে অপমানজনক কার্যকলাপ করা নিষিদ্ধ।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>ক্ষতিকর উপাদান প্রেরণ নিষিদ্ধ :</h4>
                            <p>ভাইরাস, ট্রোজান, ওয়ার্ম, স্পাইওয়্যার, টাইম বোমা, ক্যানসেলবট, বা অন্যান্য কম্পিউটার প্রোগ্রামিং রুটিন, কোড, ফাইল বা প্রোগ্রাম যা অ্যাপ্লিকেশন বা পরিষেবা বা অন্যান্য ব্যবহারকারীদের স্বার্থের ক্ষতি করতে পারে, তা প্রেরণ বা ভাগ করা সম্পূর্ণ নিষিদ্ধ।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>অননুমোদিত অ্যাকাউন্ট ব্যবহার  :</h4>
                            <p>আপনি অন্য কারো অ্যাকাউন্ট ব্যবহার বা প্রবেশ করতে পারবেন না, কিংবা তাদের পরিচয় ব্যবহার করে কোনও কার্যকলাপ সম্পন্ন করতে পারবেন না।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>বেআইনি কার্যকলাপ :</h4>
                            <p>আপনি এই অ্যাপ্লিকেশন বা ওয়েবসাইটের মাধ্যমে কোন অবৈধ কার্যকলাপ সম্পাদন বা প্রচার করতে পারবেন না, যেমন: প্রতারণা, মিথ্যা তথ্য প্রদান, বা কোনো বেআইনি লেনদেন সম্পন্ন করা।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>উপযুক্ত বিষয়বস্তু :</h4>
                            <p>আপনি কোনো ধরনের অশ্লীল, অবমাননাকর, ঘৃণাসূচক, বর্ণবাদী, বা আপত্তিজনক বিষয়বস্তু আপলোড, শেয়ার বা পোস্ট করতে পারবেন না।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>কপিরাইট লঙ্ঘন :</h4>
                            <p>আপনি কোনো তৃতীয় পক্ষের স্বত্বাধিকার, কপিরাইট, ট্রেডমার্ক, বা অন্য কোনো মেধাস্বত্ব লঙ্ঘন করতে পারবেন না। এছাড়া, আপনি কোনো অবৈধ বা অবৈধভাবে প্রাপ্ত কন্টেন্ট শেয়ার করতে পারবেন না।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>অ্যাপ্লিকেশন ব্যবহার সীমাবদ্ধতা :</h4>
                            <p>আপনি অ্যাপ্লিকেশনটিকে এটির মূল উদ্দেশ্য ছাড়া অন্য কোনো উদ্দেশ্যে ব্যবহার করতে পারবেন না। এর মধ্যে আছে ডেটা স্ক্র্যাপিং, বট চালানো, বা স্বয়ংক্রিয় প্রোগ্রামিং রুটিন ব্যবহার করা।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>প্রতিরক্ষামূলক ব্যবস্থা :</h4>
                            <p>আপনার অ্যাকাউন্টের নিরাপত্তা বজায় রাখতে আপনি একটি শক্তিশালী পাসওয়ার্ড ব্যবহার করবেন এবং আপনার অ্যাকাউন্টের কোন অননুমোদিত অ্যাক্সেসের জন্য দায়ী থাকবেন।</p>
                        </div>
                        <div className="footerSubItem">
                            <h4>ফিডব্যাক এবং প্রস্তাবনা :</h4>
                            <p>আপনি আমাদের অ্যাপ্লিকেশন বা পরিষেবাগুলির উন্নতির জন্য কোনো ফিডব্যাক বা প্রস্তাবনা দিলে তা আমাদের মালিকানাধীন হয়ে যাবে, এবং আমরা সেগুলি ব্যবহারে সম্পূর্ণ অধিকারী থাকব।</p>
                        </div>
                    
                </div>
            </div>
        </div>
        </div>
        
        <Newsletter/>
        <Footer/>
    </div>
  )
}

export default Terms