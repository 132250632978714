export const courseDeta=[
   
    {
        title:"কোর্সের জন্য কি পূর্ববর্তী কোনো অভিজ্ঞতা প্রয়োজন?",
       item:"জাভাস্ক্রিপ্ট একটি জনপ্রিয় প্রোগ্রামিং ভাষা যা ওয়েব ডেভেলপমেন্টে ব্যবহৃত হয়। জাভাস্ক্রিপ্ট কোর্স করার জন্য কিছু প্রাথমিক পূর্বশর্ত রয়েছে যা আপনার শেখার অভিজ্ঞতাকে আরও সহজ এবং কার্যকর করতে সাহায্য করবে। নিচে জাভাস্ক্রিপ্ট শেখার জন্য প্রয়োজনীয় কিছু পূর্বশর্তের তালিকা দেওয়া হলো:",
       item2:"HTML এবং CSS এর মৌলিক ধারণা। লজিক্যাল চিন্তা ও সমস্যা সমাধানের দক্ষতা। মৌলিক প্রোগ্রামিং ধারণা। কম্পিউটার এবং ইন্টারনেট ব্যবহারের দক্ষতা। অনলাইনে শেখার ইচ্ছা ও ধৈর্য।",
        flushID:"flush-headingThree",
        flushCollaps:"flush-collapseThree",
        flushCollapsTarget:"#flush-collapseThree",
    },
    {
        title:"কোর্স শেষ করতে যদি সমস্যা হয়, তাহলে কী করা উচিত?",
        item:"যদি কোর্স শেষ করতে কোনো সমস্যায় পড়েন, আপনি আমাদের সহায়ক দলের সাথে যোগাযোগ করতে পারেন। এছাড়াও, ফোরামে অন্যান্য শিক্ষার্থীদের সাথে আপনার সমস্যাগুলো আলোচনা করতে পারেন। আমাদের শিক্ষকরাও আপনার জন্য সহায়ক হতে পারেন।",
        flushID:"flush-headingFour",
        flushCollaps:"flush-collapseFour",
        flushCollapsTarget:"#flush-collapseFour"
    },
    {
        title:"অনলাইন পরীক্ষার জন্য প্রস্তুতি নেওয়ার জন্য:",
        item:"প্রতিটি মডিউল মনোযোগ দিয়ে পড়ুন। প্র্যাকটিস কুইজগুলো সমাধান করুন। ইনস্ট্রাক্টরের সাথে আপনার প্রশ্নগুলি আলোচনা করুন। পূর্ববর্তী প্রশ্নপত্র এবং নমুনা প্রশ্নপত্র দেখুন।",
        item3:"অনলাইন পরীক্ষার জন্য প্রস্তুতি নেওয়ার জন্য:",
        flushID:"flush-headingFive",
        flushCollaps:"flush-collapseFive",
        flushCollapsTarget:"#flush-collapseFive"
    },
    {
        title:"কিভাবে আপনি ক্যারিয়ার গাইডেন্স পেতে পারেন?",
        item:"আমরা আমাদের শিক্ষার্থীদের জন্য ক্যারিয়ার গাইডেন্স এবং পরামর্শ প্রদান করি। আপনি আমাদের ক্যারিয়ার সার্ভিস টিমের সাথে যোগাযোগ করতে পারেন অথবা লাইভ ওয়েবিনারে অংশগ্রহণ করতে পারেন।",
       
        flushID:"flush-headingSix",
        flushCollaps:"flush-collapseSix",
        flushCollapsTarget:"#flush-collapseSix"
    },
    
    
]