export const Chpt4SubDetail=[
    {title:"হেডিং",
        name:"Heading"
    },
    {title:"টেক্সট ফরমেটিং",
        name:"text-formatting"},
    {title:"সাবস্ক্রিপ্ট ও সুপারস্ক্রিপ্ট",
        name:"sub-sup"},
    {title:"তালিকা বা লিস্ট",
        name:"list"},
    {title:"হাইপারলিংক",
        name:"hyper-link"},
    {title:"ছবি বা ইমেজ",
        name:"img"},
    {title:"সারণী বা টেবিল",
        name:"table"},
    ]