import Footer from "../../Footer/Footer"
import Nav from "../../Nav/Nav"
import Newsletter from "../../newsletter/Newsletter"
import TopBar from "../../topbar/TopBar"
import "../Terms/Terms.css"

const Refund = () => {
  return (
    <div>
        <TopBar/>
        <Nav/>

        <div className="footerItem">
        <div className="container">
        <div className="faqTitle text-center mt-5 mb-4">
            <span>রিফান্ড পলিসি</span> 
            </div>
            <div className="row">
                <div className="col-lg-12 termsAndCondition">
                    <div className="footerSubItem">
                    <h4>রিফান্ডের যোগ্যতা :</h4>
                    <p>আমাদের প্ল্যাটফর্ম থেকে ক্রয়কৃত কোর্স বা পরিষেবার জন্য রিফান্ডের যোগ্যতা নির্ধারণ করতে, নিচের শর্তগুলো পূরণ করতে হবে:</p>
                    <ul>
                        <li>কোর্স বা পরিষেবার জন্য পেমেন্ট সম্পন্ন হওয়ার পর ৫ দিনের মধ্যে রিফান্ডের জন্য আবেদন করতে হবে।</li>
                        <li>যদি কোর্সটি এখনও সম্পূর্ণ না করা হয়ে থাকে বা আপনি ১৫% এর বেশি কন্টেন্ট অ্যাক্সেস না করে থাকেন।</li>
                        <li>আপনার রিফান্ডের অনুরোধ গ্রহণযোগ্য হতে হবে এবং এটি প্রমাণ করতে হবে যে আপনি আমাদের প্ল্যাটফর্মে ভুল বা অপ্রয়োজনীয় কোর্স কেনার প্রক্রিয়ায় রয়েছেন।</li>
                       
                    </ul>
                    </div>



                    <div className="footerSubItem">
                    <h4>রিফান্ডের সময়সীমা :</h4>
                    <p>আপনার রিফান্ডের অনুরোধ গ্রহণযোগ্য হলে, আমরা ৭-১০ কার্যদিবসের মধ্যে আপনার পেমেন্ট মূলত অর্থপ্রদানের মূল পদ্ধতির মাধ্যমে ফেরত পাঠাব।</p>
                    </div>
                    
                    <div className="footerSubItem">
                    <h4>অতিরিক্ত তথ্য :</h4>
                    <p>কোনো রিফান্ড অনুরোধ যদি প্রক্রিয়া করা যায় না, তবে আপনি আমাদের সাপোর্ট টিমের সাথে যোগাযোগ করতে পারেন। কিছু ক্ষেত্রে, আমরা আপনাকে বিকল্প সমাধানের প্রস্তাব দিতে পারি।</p>
                    </div>
                   

                    <div className="footerSubItem">
                    <h4>নন-রিফান্ডেবল আইটেম :</h4>
                    <p>নিম্নলিখিত আইটেম বা পরিষেবাগুলোর জন্য রিফান্ড প্রযোজ্য নয়:</p>
                    <ul>
                        <li>বিশেষ ডিসকাউন্ট বা অফারে কেনা কোর্স।</li>
                        <li>যে কোর্সগুলো ইতিমধ্যে ১৫% এর বেশি সম্পন্ন হয়েছে।</li>
                        <li>যদি কোর্স বা পরিষেবার ক্রয় করার ৫ দিনের মধ্যে রিফান্ডের জন্য আবেদন করা না হয়।</li>
                    </ul>
                    </div>


                    
                    <div className="footerSubItem">
                    <h4>যোগাযোগের ঠিকানা :</h4>
                    <p>যদি আপনার রিফান্ড পলিসি সম্পর্কে কোনো প্রশ্ন থাকে, তাহলে নিচের ঠিকানায় আমাদের সাথে যোগাযোগ করুন:</p>
                    
                    <ul>
                        <li>[ই-মেইল ঠিকানা]</li>
                        <li>[ফোন নম্বর]</li>
                        <li>[অফিসের ঠিকানা]</li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
      
        <Newsletter/>
        <Footer/>
    </div>
  )
}

export default Refund