import { default as course3, default as course5 } from "../../assets/1708681424phpGBuKdU.jpeg"
import { default as course2, default as course4 } from "../../assets/excel.jpg"
import { default as course1, default as course6 } from "../../assets/python.jpg"
import user1 from "../../assets/touhidSir36.png"
export const AllCourseMenu=[
    {
        tilte:"HSC ICT সম্পূর্ণ একাডেমিক কোর্স",
        price:"৪০০০",
        people:"১২৪",
        vdo:"৫৩",
        time:"১০ ঘন্টা ২৭ মিনিট",
        mnth:"৩",
        class:"cardImg1 cardImg",
        pastPrice:"৪৫০০" ,
        instructor:user1,
    courseImg:course1,
        instructorName:"মো. তৌহিদ হোসেন"
    },
    {
        tilte:"ফুল স্ট্যাক ওয়েব ডেভেলপমেন্ট",
        price:"৩৫০০",
        people:"৫৬",
        vdo:"৪৭",
        time:"১৩ ঘন্টা ১১ মিনিট",
        mnth:"৬",
        class:"cardImg2 cardImg",
        pastPrice:"৪০০০" ,
        instructor:user1,
    courseImg:course2,
        instructorName:"মো. তৌহিদ হোসেন"

    },
    {
        tilte:"মাইক্রোসফট অফিস অ্যাপ্লিকেশন",
        price:"২০০০",
        people:"১৫২",
        vdo:"৩২",
        time:"৯ ঘন্টা ৩০ মিনিট",
        mnth:"৩",
        class:"cardImg3 cardImg" ,
    pastPrice:"২৫০০" ,
    courseImg:course3,
    instructor:user1,
        instructorName:"মো. তৌহিদ হোসেন"
},
    {
        tilte:"HSC ICT সম্পূর্ণ একাডেমিক কোর্স",
        price:"২০০০",
        people:"৯৬ ",
        vdo:"৩৩",
        time:"২০ ঘন্টা ৩০ মিনিট",
        mnth:"৪ ",
        class:"cardImg4 cardImg",
        pastPrice:"২৫০০" ,
        instructor:user1,
    courseImg:course4,
        instructorName:"মো. তৌহিদ হোসেন"
    },
    {
        tilte:"ফুল স্ট্যাক ওয়েব ডেভেলপমেন্ট",
        price:"১৮০০",
        people:"৪২",
        vdo:"২৬",   
        time:"১১ ঘন্টা ২৫ মিনিট",
        mnth:"৪ ",
        class:"cardImg5 cardImg",
        pastPrice:"২২০০" ,
    courseImg:course5,
    instructor:user1,
        instructorName:"মো. তৌহিদ হোসেন"

    },
    {
        tilte:"মাইক্রোসফট অফিস অ্যাপ্লিকেশন",
        price:"৮০০",
        people:"১২৪",
        vdo:"২৫",
        time:" ৮ ঘন্টা ৩০ মিনিট",
        mnth:"৩",
        class:"cardImg6 cardImg" ,
    pastPrice:"১২০০" ,
    courseImg:course6,
    instructor:user1,
        instructorName:"মো. তৌহিদ হোসেন"
}
]