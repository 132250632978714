import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import Footer from '../../../Footer/Footer'
import Nav from '../../../Nav/Nav'
import Newsletter from '../../../newsletter/Newsletter'
import TopBar from '../../../topbar/TopBar'
import "../GlobalVillage/GlobalVillage.css"
import { Chpt1SubDetail } from '../../../chapterSub/Chpt1Sub/Chpt1SubDetail'

const VR = () => {
  return (
        <div>
            <TopBar/>
            <Nav/>
        <div className="container chapterOneMain">
            <div className="row chapterOneMain py-3">
                <div className="col-lg-9">
                <div className="booktop">
            <h1>প্রথম অধ্যায়</h1>
            <h3>তথ্য ও যোগাযোগ প্রযুক্তি : বিশ্ব ও বাংলাদেশ প্রেক্ষিত</h3>
            <h2>ভার্চুয়াল রিয়েলিটি (Virtual Reality)</h2>
        </div>
        <p className='mt-4'>আমাদের ইন্দ্রিয়গুলো (চোখ, কান, নাক, ত্বক প্রভৃতি) পরিবেশ থেকে তথ্য সংগ্রহ করে মস্তিষ্কে পাঠায়। এইসব ইন্দ্রিয় থেকে প্রাপ্ত তথ্যের ভিত্তিতেই মস্তিষ্ক আমাদের জন্য বাস্তবতার অনুভূতি তৈরি করে। সুতরাং মস্তিষ্কের কাছে আমরা যদি নিজেদের ইচ্ছামত তথ্য পাঠাতে পারি তাহলে তো মস্তিষ্ক সেই অনুযায়ী বাস্তব অনুভূতিই তৈরি করবে, তাইনা?</p>
        <p>ভার্চুয়াল রিয়েলিটি (VR) শব্দের আক্ষারিক অর্থ কৃত্তিম বাস্তবতা। এটি এমন এক প্রযুক্তি যা ব্যবহারে কৃত্তিমভাবে তৈরি কোন ত্রিমাত্রিক পরিবেশকে আমাদের কাছে বাস্তব বলে অনুভুত হয়। এই প্রযুক্তিতে ‘VR হেডসেট’ এর মাধ্যমে ত্রিমাত্রিক ইমেজকে উপস্থাপন করা হয়। একইসাথে হেডফোন, বিশেষ হ্যান্ড গ্লাভস এর মাধ্যমে যাথাক্রমে শব্দ ও স্পর্শের অনুভূতি যুক্ত করা হয় যা ত্রিমাত্রিক পরিবেশকে আরো বাস্তব হিসেবে অনূভুত করায়। </p>
        
        <h4><b>প্রাত্যাহিক জীবনে ভার্চুয়াল রিয়েলিটির প্রভাব : </b></h4>
      
        <ul>
            <li >
            <b>চিকিৎসাক্ষেত্রে  :</b> উন্নত বিশ্বে নতুন ডাক্তারদের প্রশিক্ষণ ও নতুন চিকিৎসা পদ্ধতি পরীক্ষামলূকভাবে চালাতে ভার্চুয়াল রিয়েলিটি ব্যবহৃত হচ্ছে। এই প্রযুক্তি ব্যবহারের ফলে জটিল বাস্তব অপারেশনের অভিজ্ঞতা সহজে ও ঝুকিহীন ভাবে অর্জন করে নতুন ডাক্তারগণ দক্ষ হয়ে উঠছেন। জটিল অপারেশন, অঙ্গপ্রত্যঙ্গের ক্ষুদ্রাতিক্ষুদ্র গঠন ও কার্যপ্রণালী পর্যবেক্ষণ, ডিএনএ পর্যালোচনা প্রভৃতি সম্পর্কে ব্যাপকভাবে জানাও গবেষণা চালানো সম্ভব হচ্ছে ভার্চুয়াল রিয়েলিটি এর মাধ্যমে। 
            </li>
            <li >
            <b>শিক্ষা ও গবেষণায় :</b> জটিল বিষয়গুলো ভার্চুয়াল রিয়েলিটির মাধ্যমে শিক্ষার্থীদের সামনে সহজবোধ্য ও আকর্ষণীয়ভাবে উপস্থাপন করা যায়। জটিল অণুর আণবিক গঠন, ডিএনএ গঠন যা কোনো অবস্থাতেই বাস্তবে অবলোকন সম্ভব নয় সেগুলো ভার্চুয়াল রিয়েলিটির পরিবেশে সিমুলেশনের মাধ্যমে দেখা সম্ভব হচ্ছে।
            </li>
            <li >
            <b>বিনোদনে :</b>ভার্চুয়াল রিয়েলিটি নির্ভর চলচ্চিত্র, কার্টুন, গেমস মানুষের কাছে জনপ্রিয় হয়ে উঠছে। যেসব স্থানে ভ্রমণ করা সবার পক্ষে সহজসাধ্য নয়, এই প্রযুক্তির মাধ্যমে সেসব যায়গা ভ্রমনের বাস্তব অনুভুতি সহজেই পাওয়া যাচ্ছে।
            </li>
            <li >
            <b>সামরিক প্রশিক্ষণে :</b> ভার্চুয়াল রিয়েলিটিতে সত্যিকার যুদ্ধক্ষেত্রের অনূরূপ পরিবেশ তৈরি করে সৈনিকদের উন্নত ও নিখুঁত প্রশিক্ষণ দেওয়া যায়। দক্ষ সৈনিক গড়ে তুলতে বাস্তবে যুদ্ধকালীন বিপজ্জনক পরিস্থিতির সাথে পরিচয় ও তাদের করণীয় সম্পর্কে আগে থেকেই ধারণা দেওয়া যায়। 
            </li>
            <li >
            <b>যানবাহন চালানো ও প্রশিক্ষণে :</b>  বৈমানিকরা বাস্তবে আসল বিমান উড্ডয়নের পূর্বেই ফ্লাইট সিমুলেটরে বিমান পরিচালনার বাস্তব জগৎকে অনুধাবন করে থাকেন। এ ছাড়াও মোটরগাড়ি, জাহাজ ইত্যাদি চালানোর প্রশিক্ষণে কোনো দুর্ঘটনা ছাড়াই বাস্তবে সেসব যানবাহন চালানোর দক্ষতা অর্জন করতে পারে। 
            </li>
            </ul>
            <h4><b>ইতিবাচক প্রভাবের পাশাপাশি ভার্চুয়াল রিয়েলিটির নেতিবাচক প্রভাবও রয়েছে। যেমন:</b></h4>
            <ul>
                <li>ভার্চুয়াল রিয়েলিটি সরঞ্জামাদি এর দাম অনেক বেশি হওয়ায় এটি সর্বসাধারণের জন্য ব্যবহার করা কষ্টকর।</li>
                <li>অতিরিক্ত ব্যবহারে এই প্রযুক্তি মানুষের দৃষ্টিশক্তি ও শ্রবণশক্তি এর ব্যাপক ক্ষতিসাধন করতে পারে।</li>
                <li>অতিরিক্ত সময় কাল্পনিক জগতে কাটানোর কারনে কাল্পনিক ও বাস্তবতার ভেতর পার্থক্য করা কঠিন হয়ে পড়বে এবং মানুষের সামাজিক সম্পর্কের উপরও পড়বে নেতিবাচক প্রভাব।</li>
               
            </ul>
                </div>
                <div className="col-lg-3 ">
                <div className="chapterLeft">
                {
      Chpt1SubDetail.map((item,index)=>{
        return(
          <Link key={index} to={item.name} className="mb-4">
             <h4 className='chapterOne'>{item.title}</h4>
          </Link>
        )
      })
    }
                </div>
                </div>
        </div>
      </div>
      <Newsletter/>
      <Footer/>
        </div>
  )
}

export default VR