import book1 from '../../assets/book1.webp'
import book2 from '../../assets/book2.webp'
import book3 from '../../assets/book3.webp'
import book4 from '../../assets/book4.png'
import book5 from '../../assets/book5.webp'
import book6 from '../../assets/book6.webp'

export const ictBooksDetails=[
    {
       bookImg:book1,
       url:"https://drive.google.com/file/d/1_Yg_Won-jGJWPj1jrm3hkM_oWdoiFhil/view?usp=sharing"
    },
    {
       bookImg:book2,
       url:"https://drive.google.com/file/d/1Cy1gQ3do8QQUikNslDHMP8ztO5ef9x0N/view?usp=sharing"
    },
    {
       bookImg:book3,
       url:"https://drive.google.com/file/d/1yotKINpNe-V91Fpz-cCRv8sf-hwmOowz/view?usp=sharing"
    },
    {
       bookImg:book4,
       url:"https://drive.google.com/file/d/1Hf9wEvF5qNqLlOt6r1pHCeoMWQJvnA3A/view?usp=sharing"
    },
    {
       bookImg:book5,
       url:"https://drive.google.com/file/d/1Cy1gQ3do8QQUikNslDHMP8ztO5ef9x0N/view?usp=sharing"
    },
    
    
]
export const ictBooksDetails2=[
    {
        bookImg:book6,
        url:"https://drive.google.com/file/d/1sZe5_fVqoArJlLnXvNcIifsqw7uGaTeD/view?usp=sharing"
     },
    
    
]