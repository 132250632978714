export const question=[
        {
            question: "বিশ্বগ্রামের ধারণাটি সর্বপ্রথম প্রবর্তন করেন।",
            options: ["মার্শাল ম্যাকলুহান", "বিল গেটস", "মার্ক জাকারবার্গ", "টিম বার্নার্স লি"],
            answer: "মার্শাল ম্যাকলুহান",
          },
         
          {
            question: "বিশ্বগ্রামের মেরুদণ্ড কোনটি ?",
            options: ["ডেটা", "কানেকটিভিটি", "সফটওয়্যার", "হার্ডওয়্যার"],
            answer: "কানেকটিভিটি",
          },
          {
            question: "বিশ্বগ্রাম ধারণার সাথে কোন বিষয়টি বিশেষভাবে সম্পৃক্ত ?",
            options: ["গ্রামের সাথে শহরের সহজ যােগাযােগ ", "বিশ্বব্যাপী গ্রামকে নগরে পরিবর্তন ", "শিক্ষার অবাধ সুযােগ-সুবিধার বিস্তার ", "ইন্টারনেট সুবিধার ব্যাপক প্রসার "],
            answer: "ইন্টারনেট সুবিধার ব্যাপক প্রসার ",
          },
          {
            question: "সমগ্র পৃথিবী এখন একটি গ্রামে পরিণত হয়েছে। এখন বিভিন্ন দেশের মানুষ খুব সহজেই একজন অন্য জনের সুখে দুঃখে পাশাপাশি ও প্রতিবেশীর মতাে ভাব বিনিময় করছে। উদ্দীপকে কোন বিষয় সম্পর্কে বলা হয়েছে?",
            options: ["নেটওয়ার্ক", "বিশ্বগ্রাম", "ন্যানােটেকনােলজি", " ভার্চুয়াল রিয়েলিটি "],
            answer: "বিশ্বগ্রাম",
          },
          {
            question: "বহুল ব্যবহৃত সামাজিক যােগাযােগ মাধ্যম কোনটি? ",
            options: ["Zorpia", "Tagged", "Twitter", "Facebook"],
            answer: "Facebook",
          },
          {
            question: "ফ্রিল্যান্সার কে?",
            options: ["দীর্ঘমেয়াদি চুক্তিতে কর্মরত ব্যক্তি ", "যিনি স্বাধীনভাবে প্রতিষ্ঠানের কাজ করেন।", "সুনির্দিষ্ট কাজের লাইসেন্স নিয়ে কাজ করেন। ", "যিনি নিয়মমাফিক ১০টা-৫টা অফিস করেন"],
            answer: "যিনি স্বাধীনভাবে প্রতিষ্ঠানের কাজ করেন।",
          },
          {
            question: "ইন্টারনেট ব্যবহার করে কর্মসংস্থানের সুযােগকে কী বলা হয়? ",
            options: ["ই-বিজনেস ", "ই-গভর্নেন্স ", "আউটসাের্সিং", "ই-কমার্স "],
            answer: "আউটসাের্সিং",
          },
          
          {
            question: "khanacademy.org নামক ওয়েবসাইটটির প্রতিষ্ঠাতা কে?",
            options: ["মার্শাল ম্যাকলুহান ", "রে টমলিনসন", "সালমান খান", "জাভেদ করিম"],
            answer: "সালমান খান",
          },
          {
            question: "ইন্টারনেটের মাধ্যমে ব্যবসায় পরিচালনা করাকে কী বলে? ",
            options: ["ই-মেইল", "ই-ট্রেড ", "ই-গভর্নেন্স ", "ই-কমার্স"],
            answer: "ই-কমার্স",
          },
          {
            question: "গুগলের ভার্চুয়াল চ্যাটিং সার্ভিসের নাম কী? ",
            options: ["Google ", "Lively", "Virtual", "Be Virtuality "],
            answer: "Lively",
          },
        
          {
            question: "মাইসিন কোন প্রকৃতির কৃত্রিম ব্যবস্থা? ",
            options: ["যােগাযােগ ", "চিকিৎসা", "কর্ম", "বাসস্থান"],
            answer: "চিকিৎসা",
          },
          {
            question: "ভার্চুয়াল রিয়েলিটিতে কত মাত্রিক জগৎ তৈরি হয়? ",
            options: ["চতুর্মাত্রিক ", "দ্বিমাত্রিক", "ত্রিমাত্রিক", "একমাত্রিক"],
            answer: "ত্রিমাত্রিক",
          },
          {
            question: "কোনটি ভার্চুয়াল রিয়েলিটিতে ব্যবহৃত হয়? ",
            options: ["বায়ােলজিক্যাল ডেটা", "দ্বিমাত্রিক সিমুলেশন", "হ্যান্ড জিওমেট্রি", "ত্রিমাত্রিক সিমুলেশন"],
            answer: "ত্রিমাত্রিক সিমুলেশন",
          },
          {
            question: "ভিজুয়্যাল ম্যানুফ্যাকচারিং সিস্টেম অটোমােশনে ব্যবহৃত হয়- ",
            options: ["CPD", "CAM", "CAE", "CAD"],
            answer: "CAD",
          },
          {
            question: " কম্পিউটার সিম্যুলেশন প্রয়ােগের ক্ষেত্র কোনটি? [চ, বাে, ‘১৭] ",
            options: ["ভার্চুয়াল রিয়েলিটি", "ক্রায়ােসার্জারি", "ইন্টারনেট ", "ভিডিও কনফারেন্সিং "],
            answer: "ভার্চুয়াল রিয়েলিটি",
          },
          {
            question: " কনটেন্ট ম্যানেজমেন্ট সিস্টেম (CMS) কী?",
            options: ["একটি প্রোগ্রামিং ভাষা", "একটি নেটওয়ার্ক প্রোটোকল", "একটি সফটওয়্যার প্ল্যাটফর্ম", "একটি হার্ডওয়্যার ডিভাইস"],
            answer: "একটি সফটওয়্যার প্ল্যাটফর্ম",
          },
          {
            question: " ইন্টারনেটের মাধ্যমে সংযুক্ত কম্পিউটারগুলি কোন প্রোটোকল ব্যবহার করে?",
            options: ["SMTP", "FTP", "TCP/IP", "HTTP"],
            answer: "TCP/IP",
          },
          {
            question: "সফটওয়্যার ডেভেলপমেন্ট লাইফ সাইকেল (SDLC) এর কোন পর্যায়ে প্রকল্পের কার্যকারিতা মূল্যায়ন করা হয়?",
            options: ["নকশা", "বাস্তবায়ন", "রক্ষণাবেক্ষণ", "পরিকল্পনা"],
            answer: "রক্ষণাবেক্ষণ",
          },
          {
            question: "কোন প্রোগ্রামিং ভাষা মূলত ওয়েব ডেভেলপমেন্টের জন্য ব্যবহৃত হয়?",
            options: ["JavaScript", "Java", "Python", "C"],
            answer: "JavaScript",
          },
          {
            question: "ডেটাবেস ম্যানেজমেন্ট সিস্টেম (DBMS) এর উদাহরণ কোনটি?",
            options: ["Photoshop", "MySQL", "CorelDRAW", "MS Word"],
            answer: "MySQL",
          },
          {
            question: "কোনটি একটি অপারেটিং সিস্টেম?",
            options: ["Adobe Photoshop", "Linux", "Microsoft Word", "Microsoft Excel"],
            answer: "Linux",
          },
          {
            question: "কোনটি প্রাইমারি মেমোরি?",
            options: ["পেন ড্রাইভ", "CD-ROM", "RAM", "হার্ড ডিস্ক"],
            answer: "RAM",
          },
          {
            question: "কোনটি ওয়েব পেজ ডিজাইনের জন্য ব্যবহৃত হয়?",
            options: ["HTML", "Python", "C++", "SQL"],
            answer: "HTML",
          },
          {
            question: "CPU এর প্রধান কাজ কী?",
            options: ["ডেটা ম্যানেজমেন্ট", "ডেটা ট্রান্সফার", "ডেটা প্রসেসিং", "ডেটা সংরক্ষণ"],
            answer: "ডেটা প্রসেসিং",
          },
          {
            question: "কোন প্রোটোকল ইমেইল পাঠাতে ব্যবহৃত হয়?",
            options: ["SMTP", "TCP/IP", "HTTP", "FTP"],
            answer: "SMTP",
          },
          {
            question: "ইন্টারনেটে কোন ডোমেইন নাম সবচেয়ে সাধারণ?",
            options: [".com", ".org", ".edu", ".gov"],
            answer: ".com",
          },
          {
            question: "মাইক্রোসফট এক্সেল কোন ধরনের সফটওয়্যার?",
            options: ["গ্রাফিক্স সফটওয়্যার", "ডাটাবেজ সফটওয়্যার", "স্প্রেডশিট সফটওয়্যার", "প্রেজেন্টেশন সফটওয়্যার"],
            answer: "স্প্রেডশিট সফটওয়্যার",
          },
          {
            question: "মাইক্রোসফট এক্সেল কোন ধরনের সফটওয়্যার?",
            options: ["গ্রাফিক্স সফটওয়্যার", "ডাটাবেজ সফটওয়্যার", "স্প্রেডশিট সফটওয়্যার", "প্রেজেন্টেশন সফটওয়্যার"],
            answer: "স্প্রেডশিট সফটওয়্যার",
          },
          {
            question: "কোনটি ওয়েব ব্রাউজার নয়?",
            options: ["Adobe Acrobat", "Safari", "Mozilla Firefox", "Google Chrome"],
            answer: "Adobe Acrobat",
          },
          {
            question: "LAN এর পূর্ণরূপ কী?",
            options: ["Logical Area Network", "Large Area Network", "Long Area Network", "Local Area Network"],
            answer: "Local Area Network",
          },
        
    
]