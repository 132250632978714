import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { Chpt1SubDetail } from '../../../chapterSub/Chpt1Sub/Chpt1SubDetail'
import Footer from '../../../Footer/Footer'
import Nav from '../../../Nav/Nav'
import Newsletter from '../../../newsletter/Newsletter'
import TopBar from '../../../topbar/TopBar'
import "../GlobalVillage/GlobalVillage.css"

const CryoSurgery = () => {
  return (
        <div>
            <TopBar/>
            <Nav/>
        <div className="container chapterOneMain">
            <div className="row chapterOneMain py-3">
                <div className="col-lg-9">
                <div className="booktop">
            <h1>প্রথম অধ্যায়</h1>
            <h3>তথ্য ও যোগাযোগ প্রযুক্তি : বিশ্ব ও বাংলাদেশ প্রেক্ষিত</h3>
            <h2>ক্রায়োসার্জারি  (Cryosurgery)</h2>
        </div>
        <p className='mt-4'>‘ক্রায়ো’ (cryo) একটি গ্রীক শব্দ যার অর্থ ‘অত্যাধিক ঠান্ডা’। সুতরাং ক্রায়োসার্জারি হলো এমন একটি চিকিৎসা পদ্ধতি যার মাধ্যমে অত্যন্ত নিম্ন তাপমাত্রায় রোগাক্রান্ত কোষগুলোকে ধ্বংস হয়।</p>
        <p>আল্ট্রাসাউন্ড বা MRI এর মাধ্যমে রোগাক্রান্ত কোষের সঠিক অবস্থান চিহ্নিত করে সেখানে ক্রায়োপ্রোবের (বিশেষ নল) সাহায্যে ক্রায়োজনিক এজেন্ট (তরল গ্যাস) প্রয়োগ করা হয়। ক্রায়োজনিক এজেন্ট হিসেবে তরল নাইট্রোজেন, অক্সিজেন, কার্বন-ডাই-অক্সাইড, আর্গন প্রভৃতি ব্যবহার করা হয়। অতিরিক্ত ঠান্ডায় কোষে রক্ত সঞ্চালন ব্যাহত হয়। ফলে রক্তের মাধ্যমে প্রবাহিত অক্সিজেন আক্রান্ত কোষে না পৌঁছানোর কারনে ধীরে ধীরে কোষগুলা মারা যায়। লিভার ক্যান্সার, প্রেস্টেট ক্যান্সার, ফুসফুস ক্যান্সার, ত্বকের রোগ প্রভৃতির চিকিৎসায় ক্রায়োসার্জারি ব্যবহার করা হয়।</p>
        
        <h4><b>সুবিধা : </b></h4>
      
        <ul>
            <li >
            অপ্রয়োজনীয় কাটাছেঁড়ার জটিলতা না থাকায় এটি ব্যথা ও রক্তপাতহীন চিকিৎসা পদ্ধতি। 
            </li>
            <li >
            ক্রায়োসার্জারি চিকিৎসা পদ্ধতি তুলনামূলক সাশ্রয়ী ও প্রক্রিয়া সম্পন্ন হতে কম সময় লাগে।
            </li>
            <li >
            সার্জারির জন্য রোগীর পূর্বপ্রস্তুতি ও হাসপাতালে থাকার প্রয়োজন হয়না। 
            </li>
            <li >
            এই পদ্ধতিতে জটিল কোন পার্শ্বপ্রতিক্রিয়া নেই।
            </li>
            
            </ul>
        <h4><b>অসুবিধা : </b></h4>
      
        <ul>
            <li >
            সঠিকভাবে আক্রান্তস্থান চিহ্নিত করতে না পারলে ক্ষতিকর কোষ পুরোপুরি নির্মূল হয়না।
            </li>
            <li >
            সুস্থ হবার পরেও পুনরায় আক্রান্ত হবার সম্ভাবনা থাকে। 
            </li>
            <li >
            ত্বক ফুলে যাওয়া ও স্নায়বিক সমস্যা দেখা দিতে পারে।
            </li>
            
            </ul>
            <h4><b>ক্রায়ােসার্জারিতে আইসিটির ব্যবহার : </b></h4>
            <ul>
                <li>ক্রায়ােপ্রােব সঠিকভাবে পরিচালনার জন্য ও পার্শ্ববর্তী সুস্থ কোষগুলো যেন ক্ষতিগ্রস্ত না হয় সেজন্য আন্ট্রাসাউন্ড অথবা MRI ব্যবহার করে আক্রান্ত স্থানের সঠিক অবস্থান চিহ্নিত করা হয়।</li>
                <li>রোগীর মেডিকেল ডেটা সংগ্রহ ও বিশ্লেষণের জন্য ডেটাবেজ সফটওয়্যার ব্যবহার করা হয়।</li>
                <li>ক্রায়োপ্রোব, স্প্রে-গান প্রভৃতি ডিভাইস উৎপাদনকারী প্রতিষ্ঠানগুলো তাদের পণ্যের উৎপাদন ব্যবস্থায় কম্পিউটার ও তথ্য প্রযুক্তি ব্যাপকভাবে ব্যবহার করছে।</li>
                <li>ক্রায়ােসার্জারিতে ব্যবহৃত যন্ত্রপাতি  উৎপাদনে কাঁচামাল সংগ্রহ, উৎপাদন, বিপণন প্রতিক্ষেত্রেই তথ্য প্রযুক্তি ব্যবহার হয়।</li>
                <li>বর্তমানে ক্রায়ােসার্জারির জন্য অভিজ্ঞ ডাক্তার প্রস্তুত করতে ভার্চুয়াল রিয়েলিটির প্রয়ােগ করা হ</li>
               
            </ul>
                </div>
                <div className="col-lg-3 ">
                <div className="chapterLeft">
                {
      Chpt1SubDetail.map((item,index)=>{
        return(
          <Link key={index} to={item.name} className="mb-4">
             <h4 className='chapterOne'>{item.title}</h4>
          </Link>
        )
      })
    }
                </div>
                </div>
        </div>
      </div>
      <Newsletter/>
      <Footer/>
        </div>
  )
}

export default CryoSurgery