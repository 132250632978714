import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { Chpt1SubDetail } from '../../../chapterSub/Chpt1Sub/Chpt1SubDetail'
import Footer from '../../../Footer/Footer'
import Nav from '../../../Nav/Nav'
import Newsletter from '../../../newsletter/Newsletter'
import TopBar from '../../../topbar/TopBar'
import "../GlobalVillage/GlobalVillage.css"

const Robotics = () => {
  return (
        <div>
            <TopBar/>
            <Nav/>
        <div className="container chapterOneMain">
            <div className="row chapterOneMain py-3">
                <div className="col-lg-9">
                <div className="booktop">
            <h1>প্রথম অধ্যায়</h1>
            <h3>তথ্য ও যোগাযোগ প্রযুক্তি : বিশ্ব ও বাংলাদেশ প্রেক্ষিত</h3>
            <h2>রোবটিক্স (Robotics)</h2>
        </div>
        <p className='mt-4'>রোবটিক্স (Robotics) নামটি এসেছে রোবট (Robot) শব্দ থেকে। রোবটিক্স হলো প্রযুক্তির একটি শাখা যেখানে রোবটের ডিজাইন, নির্মাণ, কার্যক্রম ও প্রয়োগ নিয়ে কাজ করা হয়।</p>
        <p>রোবট (Robot) শব্দটির উৎপত্তি স্লাভিক শব্দ ‘Robota’ থেকে, যার অর্থ শ্রমিক। রোবট হলো কম্পিউটার নিয়ন্ত্রিত কোন মেশিন যা মানুষের মত কাজ করতে পারে। তবে সকলক্ষেত্রে রোবট দেখতে মানুষের মত নাও হতে পারে। কাজের প্রয়োজন অনুযায়ী রোবটকে নির্দিষ্ট আকার ও আকৃতিতে তৈরী করা হয়।</p>
        
        <h4><b>রোবটের উপাদান : </b></h4>
        <p><b> প্রসেসর (Processor) :</b> পেসেসরকে রোবটের মস্তিস্ক বলা চলে, এখানে প্রোগ্রাম সংরক্ষিত থাকে যা রোবটের সকল কর্মকাণ্ড নিয়ন্ত্রণ করে।  </p>
        <p><b>বৈদ্যুতিক উৎস (Power Source):</b> রোবটের যন্ত্রাংশগুলো পরিচালনার জন্য একটি পাওয়ার সিস্টেম থাকে। ব্যাটারি, সৌর শক্তি, বৈদ্যুতিক জেনারেটর, হাইড্রলিক সিস্টেম প্রভৃতি রোবটের শক্তির উৎস হিসেবে ব্যবহৃত হয়। </p>
        <p><b>ইলেকট্রিক সার্কিট (Electric Circuit) : </b>ইলেক্ট্রিক সার্কিট রোবটের মোটরসহ অন্যান্য যন্ত্রাংশে বৈদ্যুতিক সংযোগ প্রদান করে।   </p>
        <p><b>অ্যাকচুয়েটর (Actuator) : </b>অ্যাকচুয়েটরকে বলা যেতে পারে রোবটের হাত-পায়ের পেশি। এটি বৈদ্যুতিক মটরের সমন্বয়ে তৈরি এক বিশেষ ব্যবস্থা যার সাহায্যে রোবট তার অঙ্গ-প্রত্যঙ্গ নড়াচড়া করাতে পারে।  </p>
        <p><b>সেন্সর (Sensor): </b>বিভিন্ন সেন্সর (যেমন লাইট ও সাউন্ড সেন্সর, ক্যামেরা ইত্যাদি) দ্বারা পারিপার্শিক অবস্থার তথ্য ইনপুট নিয়ে রোবট বিভিন্ন বস্তুর অবস্থান ও প্রকৃতি সম্পর্কে  প্রসেসরকে তথ্য সরবরাহ করে থাকে।  </p>
        <p><b>মুভেবল বডি (Movable Body): </b>স্থান পরিবর্তনের জন্য রোবটে চাকা, যান্ত্রিক পা ও নাড়াচাড়ায় সক্ষম যন্ত্রপাতি যুক্ত থাকে।  </p>
        <p><b>ম্যানিপুলেটর (Manipulator) : </b>পারিপার্শিক বিভিন্ন বস্তুর ম্যানিপুলেশন অর্থাৎ কোন বস্তু আকড়ে ধরা, অবস্থান পরিবর্তন, সাজানো ইত্যাদি কাজের জন্য যেসকল অঙ্গ ব্যবহার করে, তাকে ম্যানিপুলেটর বলা হয়। </p>

         
            <h4><b>রোবটের সাধারণ বৈশিষ্ট্য :</b></h4>
            <ul>
                <li>রোবট প্রোগ্রাম দ্বারা নিয়ন্ত্রিত যা কোনো কাজ দ্রুত ও নিখুঁতভাবে সম্পন্ন করতে সক্ষম।</li>
                <li>রোবট বিরতিহীন ও ক্লান্তিহীনভাবে দিনরাত একটানা কাজ করতে পারে।  </li>
                <li>রোবট কৃত্রিম বুদ্ধিমত্তার সাহায্যে পারিপার্শ্বিক পরিস্থিতি অনুযায়ী সিদ্ধান্ত গ্রহণ করতে পারে।</li>
                <li>যে কোনো ঝুঁকিপূর্ণ ও ক্ষতিকর পরিবেশে স্বাচ্ছন্দ্যে কাজ করতে পারে।</li>
               
            </ul>
            <h4><b>রোবোটিক্স এর ব্যবহার :</b></h4>
            <p><b>ম্যানুফ্যাকচারিং-এ  : </b>কম্পিউটার এইডেড ম্যানুফ্যাকচারিং (ক্যাম)-এ রোবোটিক্স ব্যাপকভাবে ব্যবহৃত হয়।   বিশেষ করে যানবাহন ও গাড়ির কারখানায় রোবট ব্যবহৃত হয়।  </p>
            <p><b>শিল্প কারখানায় : </b>কারখানায় রোবোটের সাহায্যে বিপজ্জনক ও পরিশ্রমসাধ্য কাজ যেমন- ওয়েল্ডিং, ঢালাই, ভারী মাল ওঠানো বা নামানো, যন্ত্রাংশ সংযোজন ইত্যাদি করা হয়।</p>
            <p><b>মহাকাশ গবেষণায় : </b> মহাকাশ অভিযানে কঠিন পরিবেশে রোবট নিত্যনতুন তথ্য সংগ্রহ করে পাঠাচ্ছে। </p>
            <p><b>শিক্ষা ও বিনোদনেঃ : </b>শারীরিকভাবে অসুস্থ, পঙ্গু ও অটিস্টিক শিক্ষার্থীদের বিশেষ শিক্ষা ব্যবস্থায় ও শিশুদের চিত্তবিনোদন খেলনা হিসেবে রোবট ব্যবহৃত হয়।</p>
            <p><b>ঘরোয়া কাজে : </b>দৈনন্দিন ঘরোয়া কাজে গৃহকর্মী হিসেবে রোবট ব্যবহার করা হয়।</p>
            <p><b>চিকিৎসাক্ষেত্রে : </b>রোবট জটিল অপারেশনে চিকিৎসকদের সহায়তা ও রোগীদের সেবাসহ বিভিন্ন কাজ করে থাকে।</p>
            <p><b>বিপজ্জনক কাজে : </b>মানুষের জন্য করা বিপজ্জনক কাজ যেমন- বিস্ফোরক নিষ্ক্রীয়করণ, ডুবে যাওয়া জাহাজের অনুসন্ধান,পারমাণবিক কেন্দ্রে, খনির অভ্যন্তরের কাজ ইত্যাদির ক্ষেত্রে রোবোট ব্যবহৃত হয়। </p>
            <p><b>নিরাপত্তার কাজে : </b>উন্নত বিশ্বের বিভিন্ন গুরুত্বপূর্ণ ব্যবস্থাপনায় নিরাপত্তার জন্য রোবট ব্যবহৃত হয়।</p>
            <p><b>সামরিক ক্ষেত্রে : </b>বোমা নিষ্ক্রিয় করা, ভূমি মাইন শনাক্ত করা, সামরিক নানা সরঞ্জামাদি বহন এবং অন্যান্য মিলিটারি অপারেশনে রোবট ব্যবহার করা হয়।</p>
            <p><b>সূক্ষ্মাতিসূক্ষ্ম কাজে : </b>রোবট অতিক্ষুদ্র মাইক্রোসার্কিটের উপাদান পরীক্ষা, ইলেক্ট্রনিক আইসি, প্রিন্টেড সার্কিটবোর্ড ইত্যাদি কাজে ব্যবহার করা হয়।</p>
            
            
                </div>
                <div className="col-lg-3 ">
                <div className="chapterLeft">
                {
      Chpt1SubDetail.map((item,index)=>{
        return(
          <Link key={index} to={item.name} className="mb-4">
             <h4 className='chapterOne'>{item.title}</h4>
          </Link>
        )
      })
    }
                </div>
                </div>
        </div>
      </div>
      <Newsletter/>
      <Footer/>
        </div>
  )
}

export default Robotics